<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  mounted() {
  }
}
</script>

<style>
html body{
  font-size: 1rem;
  background-color: white;
}

:root {
  font-size: 12px;
}
</style>
