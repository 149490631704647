import { createRouter, createWebHistory } from 'vue-router'
// import { components } from '@/router/components'

const routes = [
  // ...components,
  {
    path: '/',
    alias: ['/asset-management'],
    name: 'asset-management',
    component: () => import('@/views/asset-management.vue'),
  },
  {
    path: '/equipment',
    name: 'equipment',
    component: () => import('@/components/equipment/components/all-components.vue'),
  },
  { // will be deleted
    path: '/erin',
    component: () => import('@/components/equipment/components/equipment-checkout/checkout-main.vue'),
  },
  // Define your other routes here
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
