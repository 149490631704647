import { h, createApp } from 'vue'
import singleSpaVue from 'single-spa-vue'

import App from './App.vue'
import router from './router'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import { BootstrapVue } from 'bootstrap-vue'
import '@/assets/base/css/global.css'
import '@/assets/base/css/dronos-element.css'
import FeatherIcon from 'vue-feather'
// import { Tooltip } from 'bootstrap'


import vueChartist from 'vue-chartist'
// import ChartistTooltip from 'chartist-plugin-tooltips-updated'

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      })
    },
  },
  handleInstance(app) {
    app.use(router)
    app.component('FeatherIcon', FeatherIcon)
    // app.directive('tooltip', Tooltip)
    // app.use(ChartistTooltip)
    app.use(vueChartist)
    // app.use(BootstrapVue)
  },
})

export const bootstrap = vueLifecycles.bootstrap
export function mount(props) {
  let mainApp = 'default'
  // Vue.prototype.$props = props
  if (props?.mainApp !== undefined) {
    mainApp = 'dronos'
  }
  // Vue.prototype.$mainApp = mainApp
  localStorage.setItem('global', mainApp)
  // app.config.globalProperties.global = mainApp
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
